// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown_BjLg\\+ select{-webkit-appearance:auto!important;-moz-appearance:auto!important;appearance:auto!important;padding-right:3rem!important;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "dropdown_BjLg+"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}.container_4xgv5{position:relative}.container_4xgv5:not(.changed_c\\+\\+0m) *{border-color:var(--color-transparent)!important}.container_4xgv5.changed_c\\+\\+0m *{border-color:var(--color-blue-200)!important}.display_yhTj2{align-items:center;display:flex;height:100%;justify-content:flex-end;padding-left:1rem;pointer-events:none;position:absolute;right:0;top:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_4xgv5",
	"changed": "changed_c++0m",
	"display": "display_yhTj2"
};
module.exports = ___CSS_LOADER_EXPORT___;
